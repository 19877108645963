import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface SelectOption {
  text?: string;
  name?: string;
  value: string | number;
  dead?: boolean;
  translate?: boolean;
  translateArgs?: any;
  tooltip?: string;
  translateTooltip?: boolean;
  group_name?: string;
  fields?: any[];
  deprecation_date?: string;
  sunset_date?: string;
  description?: string;
  disabled?: boolean;
}

interface GroupedSelectOption {
  [key: string]: SelectOption[];
}

@Component({
  selector: 'xp-select',
  template: `
    <mat-select
      *ngIf="isMultiple"
      [(value)]="value"
      (valueChange)="onValueChange($event)"
      (blur)="onBlurEvent()"
      [id]="id"
      [matTooltip]="tooltip"
      [disabled]="disabled"
      matTooltipPosition="above"
      [placeholder]="placeholder"
      [ngClass]="{ dead: isDeadSelected, disabled: isDisabledSelected }"
      [panelClass]="panelClass"
      [matTooltipClass]="state + ' ' + 'above'"
      [ngModel]="value"
      msInfiniteScroll
      [msInfiniteScrollDisable]="isInfinityScrollDisabled"
      (infiniteScroll)="loadMoreItems()"
      disableOptionCentering
      multiple
    >
      <mat-option *ngIf="isSearchEnabled">
        <ngx-mat-select-search
          [clearSearchInput]="false"
          [ngModel]="searchValue"
          (ngModelChange)="filterOptions($event)"
          [placeholderLabel]="placeholder"
          [noEntriesFoundLabel]="emptyPlaceholder"
        ></ngx-mat-select-search>
      </mat-option>
      <div *ngIf="!isGrouped">
        <mat-option
          [ngClass]="{ dead: option.dead, disabled: option.disabled }"
          *ngFor="let option of selectedOptions"
          [value]="option[valueKey]"
          [matTooltip]="option.translateTooltip ? (option.tooltip | translate) : option.tooltip"
          matTooltipPosition="after"
          matTooltipClass="after"
        >
          <div class="top-margin" *ngIf="option.description"></div>
          <span *ngIf="option.translate">{{ option.text || option.name | translate }}</span>
          <span *ngIf="!option.translate">{{ option.text || option.name }}</span>
          <span class="option-description" *ngIf="option.description && option.translate">{{
            option.description | translate
          }}</span>
          <span class="option-description" *ngIf="option.description && !option.translate">{{
            option.description
          }}</span>
        </mat-option>
      </div>
      <div *ngIf="isGrouped">
        <mat-optgroup *ngFor="let key of objectKeys(selectedOptions)" [label]="key">
          <mat-option
            [ngClass]="{ dead: option.dead, disabled: option.disabled }"
            *ngFor="let option of selectedOptions[key]"
            [value]="option[valueKey]"
            [matTooltip]="option.translateTooltip ? (option.tooltip | translate) : option.tooltip"
            matTooltipPosition="after"
            matTooltipClass="after"
          >
            <div class="top-margin" *ngIf="option.description"></div>
            <span *ngIf="option.translate">{{
              option.text || option.name | translate: option.translateArgs || {}
            }}</span>
            <span *ngIf="!option.translate">{{ option.text || option.name }}</span>
            <span class="option-description" *ngIf="option.description && option.translate">{{
              option.description | translate
            }}</span>
            <span class="option-description" *ngIf="option.description && !option.translate">{{
              option.description
            }}</span>
          </mat-option>
        </mat-optgroup>
      </div>
    </mat-select>
    <mat-select
      *ngIf="!isMultiple"
      [(value)]="value"
      (valueChange)="onValueChange($event)"
      (blur)="onBlurEvent()"
      [id]="id"
      [matTooltip]="tooltip"
      [disabled]="disabled"
      [placeholder]="isLoading ? isLoadingText : placeholder"
      [ngClass]="{ dead: isDeadSelected, disabled: isDisabledSelected }"
      [panelClass]="panelClass"
      msInfiniteScroll
      [msInfiniteScrollDisable]="isInfinityScrollDisabled"
      (infiniteScroll)="loadMoreItems()"
      disableOptionCentering
      matTooltipPosition="above"
      [matTooltipClass]="state + ' ' + 'above'"
    >
      <mat-option *ngIf="isSearchEnabled">
        <ngx-mat-select-search
          [clearSearchInput]="false"
          [ngModel]="searchValue"
          (ngModelChange)="filterOptions($event)"
          [placeholderLabel]="placeholder"
          [noEntriesFoundLabel]="emptyPlaceholder"
        ></ngx-mat-select-search>
      </mat-option>
      <div *ngIf="!isGrouped">
        <mat-option
          [ngClass]="{ dead: option.dead, disabled: option.disabled }"
          *ngFor="let option of selectedOptions"
          [value]="option[valueKey]"
          [matTooltip]="option.translateTooltip ? (option.tooltip | translate) : option.tooltip"
          matTooltipPosition="after"
          matTooltipClass="after"
        >
          <div class="top-margin" *ngIf="option.description"></div>
          <span *ngIf="option.translate">{{ option.text || option.name | translate }}</span>
          <span *ngIf="!option.translate">{{ option.text || option.name }}</span>
          <span class="option-description" *ngIf="option.description && option.translate">{{
            option.description | translate
          }}</span>
          <span class="option-description" *ngIf="option.description && !option.translate">{{
            option.description
          }}</span>
        </mat-option>
      </div>
      <div *ngIf="isGrouped">
        <mat-optgroup *ngFor="let key of objectKeys(selectedOptions)" [label]="key">
          <mat-option
            [ngClass]="{ dead: option.dead, disabled: option.disabled }"
            *ngFor="let option of selectedOptions[key]"
            [value]="option[valueKey]"
            [matTooltip]="option.translateTooltip ? (option.tooltip | translate) : option.tooltip"
            matTooltipPosition="after"
            matTooltipClass="after"
          >
            <div class="top-margin" *ngIf="option.description"></div>
            <span *ngIf="option.translate">{{
              option.text || option.name | translate: option.translateArgs || {}
            }}</span>
            <span *ngIf="!option.translate">{{ option.text || option.name }}</span>
            <span class="option-description" *ngIf="option.description && option.translate">{{
              option.description | translate
            }}</span>
            <span class="option-description" *ngIf="option.description && !option.translate">{{
              option.description
            }}</span>
          </mat-option>
        </mat-optgroup>
      </div>
      <mat-select-trigger>
        <span>{{ triggerValue }}</span>
      </mat-select-trigger>
    </mat-select>
    <input #inputRef class="hidden" [id]="id + '1'" [name]="name" type="text" />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpSelectComponent implements OnInit, OnChanges {
  @Input() value: string | number;
  @Output() valueChange = new EventEmitter<any>();
  @Input() options: SelectOption[] = [];
  @Input() isMultiple = false;
  @Input() id = String(Date.now());
  @Input() name = '';
  @Input() disabled = false;
  @Input() isLoading = false;
  @Input() isLoadingText = '';
  @Input() tooltip = '';
  @Input() placeholder = '';
  @Input() isSearchEnabled = false;
  @Input() preventEmpty = false;
  @Input() isGrouped = false;
  @Input() panelClass = '';
  @Input() state = '';
  @Input() valueKey = 'value';
  @Input() emptyPlaceholder = 'No options found.';
  @Output() onBlur = new EventEmitter<FocusEvent>();
  @ViewChild('inputRef') inputRef: ElementRef<HTMLInputElement>;

  selectedOptions: SelectOption[] | GroupedSelectOption = [];
  objectKeys = Object.keys;
  searchValue = '';

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.setOptions(this.options);

    if (this.value) {
      setTimeout(() => {
        this.inputRef.nativeElement.value = String(this.value);
        this.inputRef.nativeElement.dispatchEvent(
          new Event('input', {
            bubbles: true,
            cancelable: true,
          }),
        );
      });
    }

    if (this.preventEmpty && this.options && this.options[0]) {
      this.setFirstValue();
    }
  }

  setOptions(options = []) {
    let trimmedOptions = options || [];
    if (options && options.length > 30) {
      trimmedOptions = options.slice(0, 15);

      if (this.value) {
        const foundOption = options.find((option) => option.value === this.value);
        if (foundOption) {
          trimmedOptions = [...trimmedOptions, foundOption];
        }
      }
    }

    this.selectedOptions = this.isGrouped
      ? trimmedOptions.reduce(
          (acc, { group_name, ...rest }) => ({ ...acc, [group_name]: [...(acc[group_name] || []), { ...rest }] }),
          {},
        )
      : trimmedOptions;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && changes.options.currentValue) {
      this.setOptions(changes.options.currentValue);
      if (this.preventEmpty && changes.options.currentValue && changes.options.currentValue[0] && !this.value) {
        this.setFirstValue();
      }
    }

    if (changes.value && changes.value.currentValue) {
      this.setOptions(this.options);
    }

    if (this.preventEmpty && this.options && this.options[0] && !this.value) {
      this.setFirstValue();
    }
  }

  setFirstValue(): void {
    if (!this.value) {
      this.value = this.options[0][this.valueKey];
      this.onValueChange(this.value);
    }
  }

  onValueChange(value) {
    this.valueChange.emit(value);
    setTimeout(() => {
      this.inputRef.nativeElement.value = value;
      this.inputRef.nativeElement.dispatchEvent(
        new Event('input', {
          bubbles: true,
          cancelable: true,
        }),
      );
    });
  }

  onBlurEvent() {
    this.inputRef.nativeElement.dispatchEvent(
      new Event('blur', {
        bubbles: true,
        cancelable: true,
      }),
    );
    this.onBlur.emit();
  }

  filterOptions(value) {
    this.searchValue = value;
    this.setOptions(this.search(value));
  }

  search(value: string): SelectOption[] {
    const filter = value.toLowerCase();
    return this.options.filter((option) => (option.text || option.name).toLowerCase().includes(filter));
  }

  get isDeadSelected(): boolean {
    if ((this.options || []).find) {
      return ((this.options || []).find((option) => option[this.valueKey] === this.value) || {}).dead;
    }
    return false;
  }

  get isDisabledSelected(): boolean {
    if ((this.options || []).find) {
      return ((this.options || []).find((option) => option[this.valueKey] === this.value) || {}).disabled;
    }
    return false;
  }

  loadMoreItems() {
    this.selectedOptions = this.isGrouped
      ? this.search(this.searchValue).reduce(
          (acc, { group_name, ...rest }) => ({ ...acc, [group_name]: [...(acc[group_name] || []), { ...rest }] }),
          {},
        )
      : this.search(this.searchValue);
  }

  get isInfinityScrollDisabled(): boolean {
    return (this.options || []).length <= 30;
  }

  get triggerValue(): string {
    const selectedOption = (this.options || []).find((option) => option[this.valueKey] === this.value);
    if (!selectedOption) {
      return '';
    }
    const text = selectedOption.text || selectedOption.name;
    return selectedOption.translate ? this.translate.instant(text) : text;
  }
}
